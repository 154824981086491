






















































import { useMetaStore } from '@/modules/meta/services/store'
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import KWrapper from '@/@core/components/other/KWrapper.vue';

export default defineComponent({
    name: 'LandingFooter',
    components: {
        KWrapper,
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    setup() {
    }
})
